import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
const tooltipVariation = {
  theme: undefined,
  arrow: false,
  animation: 'scale',
  trigger: 'mouseenter',
  offset: [0, 5]
};
const popperVariation = {
  theme: 'light',
  arrow: true,
  offset: [0, 10],
  animation: undefined,
  trigger: 'click',
  interactive: true
};
function withContextMenuVariation(baseVariation) {
  return {
    ...baseVariation,
    placement: 'right-start',
    trigger: 'manual',
    arrow: false,
    offset: [0, 0]
  };
}
const TIPPY_LOADER = new InjectionToken(typeof ngDevMode !== 'undefined' && ngDevMode ? 'TIPPY_LOADER' : '');
const TIPPY_CONFIG = new InjectionToken(typeof ngDevMode !== 'undefined' && ngDevMode ? 'TIPPY_CONFIG' : '');
function provideTippyLoader(loader) {
  return makeEnvironmentProviders([{
    provide: TIPPY_LOADER,
    useValue: loader
  }]);
}
function provideTippyConfig(config) {
  return {
    provide: TIPPY_CONFIG,
    useValue: config
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { TIPPY_CONFIG, TIPPY_LOADER, popperVariation, provideTippyConfig, provideTippyLoader, tooltipVariation, withContextMenuVariation };
